import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/dpaPage/hero";
import Layout from "../components/shared/layout";

function Dpa() {
  return (
    <Layout>
      <Seo title="DPA" />
      <Hero />
    </Layout>
  );
}

export default Dpa;
